'use client';

import { styled } from 'styled-components';
import cs from 'classnames';
import * as Styled from '@lib/styles';
import TextLink from '@dvd/components/TextLink';
import Button from '@dvd/components/Button';
import CustomerLogos from '@dvd/components/CustomerLogos';
import Media from '@dvd/components/Media';
import type { ReactNode } from 'react';

type HeroProps = {
  /**
   * Eyebrow Page Title (H1)
   *
   * **NOTE**: semantically, this serves as the primary title for the page
   */
  title: string;
  /**
   * What the narrative headline says
   *
   * **NOTE**: semantically, this serves as the subtitle for the page
   */
  headline: ReactNode | string;
  /**
   * Descriptive paragraph
   */
  description: string;
  /**
   * The hashed id of the media
   */
  hashedId?: string;
  /**
   * URL of image; nesc. for showing an image intead of a media
   */
  imageSrc?: string;
  /**
   * Alt text for image; nesc. for showing an image intead of a media
   */
  imageAlt?: string;
  /**
   * What should the primary button/link point to?
   */
  primaryCtaHref?: string;
  /**
   * What should the primary button/link should say.
   */
  primaryCtaText?: string;
  /**
   * Should the primary link use a button? If not, a link will be displayed.
   */
  primaryCtaIsButton?: boolean;
  /**
   * What should the secondary button/link point to?
   */
  secondaryCtaHref?: string;
  /**
   * What should the secondary button/link should say.
   */
  secondaryCtaText?: string;
  /**
   * Should the secondary link use a button? If not, a link will be displayed.
   */
  secondaryCtaIsButton?: boolean;
  /*
   * Should the customer logos be included?
   */
  showLogos?: boolean;
  /**
   * Normal displays media stacked in the document flow.
   * - 'portrait' & 'landscape' both display the media side-by-side with the content
   * - 'portrait' best for square or 3x4 media - 'landscape' best for widescreen or 16x9 media
   */
  variant?: 'landscape' | 'normal' | 'portrait';
};

const HeroMessaging = styled.div`
  text-align: center;
`;

const HeroMedia = styled(Media)`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  margin-top: ${({ theme }) => theme.spacing[3]};
  ${({ theme }) => theme.mq.minWidth.large} {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

const HeroLogos = styled(CustomerLogos)`
  margin-top: ${({ theme }) => theme.spacing[1.5]};
  padding-block-end: 0;

  /** overrides CustomerLogos for the sake of the module */
  padding-block-start: ${({ theme }) => theme.spacing[5.25]};

  &.lessPadding {
    /** less padding on modules with video or images */
    padding-block-start: ${({ theme }) => theme.spacing[2.5]};
  }
`;

const HeroSubtitle = styled.p`
  font-family: var(--font-walsheim);
  font-size: ${({ theme }) => theme.typography.fontSize.headingOne};
  font-weight: ${({ theme }) => theme.typography.fontWeight.headingOne};
  line-height: ${({ theme }) => theme.typography.lineHeight.headingOne};
  text-wrap: balance;

  ${({ theme }) => theme.mq.minWidth.large} {
    font-size: ${({ theme }) => theme.typography.fontSize.desktop.headingOne};
  }
`;

const HeroContainer = styled.div`
  padding-block-end: ${({ theme }) => theme.spacing[3]};
  padding-block-start: ${({ theme }) => theme.spacing[4]};

  &.normal ${HeroSubtitle} {
    font-size: ${({ theme }) => theme.typography.fontSize.superTitle};
    ${({ theme }) => theme.mq.minWidth.large} {
      font-size: ${({ theme }) => theme.typography.fontSize.desktop.superTitle};
    }
  }

  &.portrait,
  &.landscape {
    ${HeroMessaging} {
      text-align: start;
    }
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    padding-block-end: ${({ theme }) => theme.spacing[4]};
    padding-block-start: ${({ theme }) => theme.spacing[6]};

    &.portrait,
    &.landscape {
      align-items: center;
      display: grid;
      ${({ theme }) => theme.grid.columns.large}
      ${HeroMessaging} {
        grid-row: 1;
      }
      ${HeroMedia} {
        grid-row: 1;

        /** Player has a hard time calculating size with any explicit margin in a grid context */
        margin: auto;
      }
      ${HeroLogos} {
        grid-column: 1 / span 12;
        grid-row: 2;
        width: 100%;
      }
    }

    &.portrait {
      ${HeroMessaging} {
        grid-column: 1 / span 6;
      }
      ${HeroMedia} {
        grid-column: 8 / span 5;
      }
    }

    &.landscape {
      ${HeroMessaging} {
        grid-column: 1 / span 5;
      }
      ${HeroMedia} {
        grid-column: 6 / span 7;
      }
    }
  }
`;

const HeroCta = ({
  href,
  isButton,
  ctaText,
}: {
  href: string;
  isButton: boolean;
  ctaText: string;
}) => (
  <p>
    {isButton ? (
      <Button color="blue500" href={href}>
        {ctaText}
      </Button>
    ) : (
      <TextLink href={href}>{ctaText}</TextLink>
    )}
  </p>
);

const ComputedMedia = ({ hashedId, imageSrc, imageAlt, variant }) => {
  const { computedHeight, computedWidth } =
    variant === 'portrait'
      ? { computedHeight: 528, computedWidth: 704 }
      : { computedHeight: 613, computedWidth: 1088 };
  if (hashedId) {
    return <HeroMedia hashedId={hashedId} />;
  }
  if (imageSrc) {
    return (
      <HeroMedia
        alt={imageAlt}
        height={computedHeight}
        src={imageSrc}
        width={computedWidth}
      />
    );
  }
  return null;
};

/**
 * Primary module leading a page's content.
 *
 * Practically speaking, just one per-page. But technically speaking, no
 * guard-rails against other usage.
 *
 * **NOTE**: because of the page's semantic structure, the Hero's title &
 * headline serve as the main title & subtitle for the document.
 */
const Hero = ({
  description,
  hashedId,
  headline,
  imageAlt,
  imageSrc,
  primaryCtaHref,
  primaryCtaIsButton = true,
  primaryCtaText = 'Get Started',
  secondaryCtaHref,
  secondaryCtaIsButton,
  secondaryCtaText = 'Learn more',
  showLogos = true,
  title,
  variant = 'normal',
}: HeroProps) => {
  return (
    <HeroContainer className={cs(variant)}>
      <HeroMessaging>
        <header>
          <Styled.Eyebrow as="h1">{title}</Styled.Eyebrow>
          <HeroSubtitle aria-roledescription="subtitle">
            {headline}
          </HeroSubtitle>
        </header>
        <Styled.BodyLg>{description}</Styled.BodyLg>
        {primaryCtaHref ? (
          <HeroCta
            ctaText={primaryCtaText}
            href={primaryCtaHref}
            isButton={primaryCtaIsButton}
          />
        ) : null}

        {secondaryCtaHref ? (
          <HeroCta
            ctaText={secondaryCtaText}
            href={secondaryCtaHref}
            isButton={secondaryCtaIsButton}
          />
        ) : null}
      </HeroMessaging>
      <ComputedMedia
        hashedId={hashedId}
        imageAlt={imageAlt}
        imageSrc={imageSrc}
        variant={variant}
      />
      {showLogos ? (
        <HeroLogos className={cs({ lessPadding: !!hashedId || !!imageSrc })} />
      ) : null}
    </HeroContainer>
  );
};

export default Hero;
