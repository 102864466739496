'use client';

import { styled } from 'styled-components';
import Media from '@dvd/components/Media';
import Button from '@dvd/components/Button';
import * as Styled from '@lib/styles';
import TextLink from '@dvd/components/TextLink';
import type { CtaProps } from '@types';

type IntroProps = {
  /**
   * What the H2 says
   */
  heading: string;
  /**
   * Should the Intro module use a button? If not a link will be displayed
   */
  useButton?: boolean;
  /**
   * This is the description
   */
  description: string;
  /**
   * Optional Eyebrow text
   */
  eyebrowText?: string;
  /**
   * The hashed id of the video; nesc. for showing video
   */
  hashedId?: string;
  /**
   * URL of image; nesc. for showing image
   */
  imageSrc?: string;
  /**
   * Alt text for image; nesc. for showing image
   */
  imageAlt?: string;
  /**
   * Optional text link. NOT TO BE USED WITH primaryBtn OR secondaryBtn.
   */
  ctaLink?: CtaProps;
  /**
   * Optional primary button. Must be used solo. NOT TO BE USED WITH ctaLink OR secondaryBtn.
   */
  primaryBtn?: CtaProps;
  /**
   * Optional secondary button. Must be used solo. NOT TO BE USED WITH ctaLink OR primaryBtn.
   */
  secondaryBtn?: CtaProps;
};

const IntroContainer = styled.div`
  padding-block: ${({ theme }) => theme.spacing[3]};

  ${({ theme }) => theme.mq.minWidth.large} {
    display: grid;
    ${({ theme }) => theme.grid.columns.large}
    padding-block: ${({ theme }) => theme.spacing[4]};
  }
`;

const IntroStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mq.minWidth.large} {
    grid-column: 2 / span 4;
  }
`;

const IntroHeading = styled.h2`
  margin: ${({ theme }) => theme.spacing[1.5]} 0;
`;

const IntroDescription = styled(Styled.BodyLg)`
  margin: 0 0 ${({ theme }) => theme.spacing[1.5]} 0;
`;

const IntroButton = styled(Button)`
  margin-block-end: ${({ theme }) => theme.spacing[1.5]};
  margin-block-start: 0;
`;

const IntroEnd = styled(Media)`
  margin: ${({ theme }) => theme.spacing[1.5]} 0;

  ${({ theme }) => theme.mq.minWidth.large} {
    grid-column: 7 / span 5;
  }
`;

const Intro = ({
  description,
  eyebrowText,
  hashedId,
  heading,
  imageAlt,
  imageSrc,
  ctaLink = null,
  primaryBtn = null,
  secondaryBtn = null,
}: IntroProps) => {
  return (
    <IntroContainer>
      <IntroStart>
        <hgroup aria-roledescription="Heading group" role="group">
          {eyebrowText ? (
            <Styled.Eyebrow aria-roledescription="subtitle">
              {eyebrowText}
            </Styled.Eyebrow>
          ) : null}
          <IntroHeading>{heading}</IntroHeading>
        </hgroup>
        <IntroDescription>{description}</IntroDescription>
        {primaryBtn ? (
          <IntroButton
            href={primaryBtn.href}
            trackingLabel={primaryBtn.trackingLabel}
            trackingProperty={primaryBtn.trackingProperty}
          >
            {primaryBtn.text}
          </IntroButton>
        ) : null}
        {secondaryBtn ? (
          <IntroButton
            href={secondaryBtn.href}
            trackingLabel={secondaryBtn.trackingLabel}
            trackingProperty={secondaryBtn.trackingProperty}
            variant="secondary"
          >
            {secondaryBtn.text}
          </IntroButton>
        ) : null}
        {ctaLink ? (
          <TextLink href={ctaLink.href}>{ctaLink.text}</TextLink>
        ) : null}
      </IntroStart>

      {hashedId ? (
        <IntroEnd hashedId={hashedId} />
      ) : (
        <IntroEnd alt={imageAlt} height={704} src={imageSrc} width={528} />
      )}
    </IntroContainer>
  );
};

export default Intro;
